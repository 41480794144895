// If you don't want to use TypeScript you can delete this file!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

type DataProps = {
  site: {
    buildTime: string
  }
}

const UsingTypescript: React.FC<PageProps<DataProps>> = ({
  data,
  path,
  location,
}) => (
  <Layout title="Small Saucepan" location={location}>
    <SEO title="Pump Mate App Privacy Policy" />
    <h1>Pump Mate App Privacy Policy</h1>
    <h2>Data collection</h2>
    <p>
      The Pump Mate app does not collect or store any of your personal data.
    </p>
    <p>
      We may collect anonymous crash logs or other anonymised logging to help
      improve the app. Individual users will not be identifiable though.
    </p>
    <p>
      We will never sell or give away any of the information above to third
      parties.
    </p>
    <h2>Changes to this policy</h2>
    <p>
      This policy may be updated from time to time. The most up to date version
      will be available on this website.
    </p>
    <h2>Queries or Concerns</h2>
    <p>
      If you have any queries or concerns about this policy or the use of your
      data, you are welcome to <Link to="/contact">contact us</Link> to discuss
      further.
    </p>
  </Layout>
)

export default UsingTypescript

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
  }
`
